import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Bible from "./components/Bible";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Tabbar from "./components/Tabbar";
// import Login from "./components/Login";
import AccessDenied from "./components/AccessDenied";
// import Policy from "./components/Policy";
import Sender from "./components/Sender";
import Share from "./components/Share";
import Write from "./components/Write";
// import { messaging } from "./firebaseConfig";
// import { getToken } from "firebase/messaging";
import { useEffect } from "react";
import My from "./components/My";

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const LayoutBody = styled.div``;

const FlexColGap = styled.div`
  /* animation: ${slideIn} 0.5s forwards; */
  border: none;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 85px;
  gap: 8px;
  align-items: flex-end;
  padding-left: 16px;
  padding-right: 16px;

  /* border-right: solid 0.5px #ccc;
  border-left: solid 0.5px #ccc; */
  @media (orientation: portrait) {
    /* border: none;
    max-width: 600px; */
  }
`;

const Layout = () => {
  return (
    <LayoutBody>
      <Navbar />
      <Tabbar />
      <Outlet />
      <Footer />
    </LayoutBody>
  );
};

const pathRouter = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: (
          <FlexColGap>
            <Bible />
          </FlexColGap>
        ),
      },
      {
        path: "/my",
        element: (
          <FlexColGap>
            <My />
          </FlexColGap>
        ),
      },
      {
        path: "/write",
        element: (
          <FlexColGap>
            <Write />
          </FlexColGap>
        ),
      },
      {
        path: "/share",
        element: (
          <FlexColGap>
            <Share />
          </FlexColGap>
        ),
      },
      // {
      //   path: "/policy",
      //   element: (
      //     <FlexColGap>
      //       <Policy />
      //     </FlexColGap>
      //   ),
      // },
      {
        path: "/send",
        element: (
          <FlexColGap>
            <Sender />
          </FlexColGap>
        ),
      },
      {
        path: "/*",
        element: (
          <FlexColGap>
            <AccessDenied />
          </FlexColGap>
        ),
      },
    ],
  },
];

const router = createBrowserRouter(pathRouter);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
